<template>
  <div class="sidenav">
    <ul role="list" class="sidenav-list">
      <li class="sidenav-list-item">
        <ul role="list" class="list-content">
          <li v-for="(route, key) in routes" :key="route.label">
            <SidenavItem
              :key="route.id"
              :active="activeRoute === key"
              :label="route.label"
              :icon="route.icon"
              :href="route.href"
              :children="route.children"
            />
          </li>
        </ul>
        <ul role="list" class="list-content">
          <!-- <li v-for="route in routes)" :key="route.name" className="cursor-pointer">
            <SidenavItem
              :key="route.id"
              :name="route.name"
              :icon="route.icon"
              :href="route.href"
              :children="route.children"
            />
          </li> -->
          <button v-if="onLogout" @click.prevent="onLogout">
            <SidenavItem
              key="logout"
              label="Sair"
              icon="/assets/img/sidebar/logout.svg"
            />
          </button>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavItem from '@/components/base/sidebar/sidenav-item'

export default {
  name: 'Sidenav',
  components: { SidenavItem },
  props: {
    routes: { type: Array, required: false, default: () => [] },
    onLogout: { type: Function, required: false, default: undefined },
    activeRoute: { type: String, required: false, default: '' }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.sidenav {
  display: flex;
  height: 100%;

  &-list {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
  }

  &-list-item {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .list-content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  ul {
    padding-left: 0;
  }
}
</style>
