<template>
  <div data-app>
    <div class="navbar">
      <div class="container pa-0">        
        <h5>
          <nuxt-link 
            to="/"
            :disabled="zap === undefined"
          >
            <img src="/assets/img/clickzap_logo_outline_dark.png">
          </nuxt-link>
        </h5>
        <span>
          <a 
            href="https://clickzap.com.br/tutoriais/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fas fa-question-circle" />
            &nbsp;
          </a>
          <!-- <a 
            href="javascript:void(0)" 
            class="wpp"
          >
            <i class="fas fa-bell" />
          </a> -->
          <a 
            href="javascript:void(0)" 
            @click="toggleMenu"
          >
            {{ loggedUsername }} <i class="fas fa-angle-down" />
          </a>
          <span 
            v-show="menu"
            class="drop-menu"
          >
            <template
              v-if="zap !== undefined"
            >
              <nuxt-link 
                :to="{ name: 'index' }"
                @click.native="toggleMenu"
              ><i class="fas fa-check-double" /> Dashboard</nuxt-link>

              <nuxt-link 
                :to="{ name: 'index-sequencia' }"
                @click.native="toggleMenu"
              ><i class="fas fa-filter" /> Fluxos</nuxt-link>

              <nuxt-link 
                :to="{ name: 'index-broadcast' }"
                @click.native="toggleMenu"
              ><i class="far fa-paper-plane" /> Transmissão</nuxt-link>

              <nuxt-link 
                :to="{ name: 'index-leads' }"
                @click.native="toggleMenu"
              ><i class="fas fa-users" /> Contatos</nuxt-link>

              <nuxt-link
                :to="{ name: 'livechat-uuid' }"
                @click.native="toggleMenu"
              ><i class="fab fa-whatsapp-square" /> Live chat</nuxt-link>

              <nuxt-link 
                :to="{ name: 'index-configuracao' }"
                @click.native="toggleMenu"
              ><i class="fas fa-cogs" /> Configurações</nuxt-link>

              <hr>
              <nuxt-link 
                :to="{ name : 'index-myaccount' }"
                @click.native="toggleMenu"
              >
                Minha Conta
              </nuxt-link> 
              <hr>
            </template>
            <a 
              href="javascript:void(0)" 
              @click.prevent="logout"
            >Sair</a>
          </span>
        </span>
      </div>
    </div>
    <div
      v-if="loggedUser.is_free"
      :style="countDown == 'EXPIROU' ? 'background-color: rgb(244, 67, 54)' :'background-color: rgb(242, 137, 9)'"
    >
      <button
        v-if="countDown"
        class="white--text"
        style="width: 100%; padding: 10px; line-height: 25px"
        @click="goToPlans"
      > 
        <span v-if="countDown == 'EXPIROU'">
          A sua conta está inativa. Para reativá-la, <strong>Clique Aqui.</strong>
        </span>
        <span v-else>
          O seu teste grátis termina em {{ countDown }}, <strong>assine agora.</strong>
        </span>
      </button>
    </div>
    <div
      class="container container-page mt-4"
      style="display: flex; justify-content: center; align-items: center;"
    >
      <nuxt />
    </div>

    <div class="page-footer">
      <label>© {{ currentYear }} · Clickzap. Todos os direitos reservados.</label>
    </div>
    <!-- <cz-expired ref="expired" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { redirectUserToPayment } from '@/helpers/redirects'
// import CzExpired from '@/components/cz-expired'
export default {
  // components: {
  //   CzExpired
  // },
  data() {
    return {
      menu: false,
      countDown: null
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loggedInUser',
      'loggedUser',
      'loggedUserExpirated',
      'loggedUserInactive'
    ]),
    currentYear() {
      return new Date().getFullYear() || ''
    },
    loggedUsername() {
      return this.loggedInUser ? this.loggedInUser.name.split(' ')[0] : '-'
    },
    zap() {
      return this.$store.state.zaps.zap || {}
    }
  },
  watch: {
    $route(to, from) {
      if (
        window.localStorage.getItem('auth.strategy') == 'chat' &&
        to.name != 'livechat'
      )
        this.$router.push({ name: 'livechat' })
      if (this.loggedUserExpirated || this.loggedUserInactive) {
        // this.$refs.expired.open()
        this.goToPlans()
      }
    }
  },
  notifications: {
    showPusherMessage: {
      title: '',
      message: 'Failed to authenticate',
      type: 'success'
    }
  },
  created() {
    const that = this
    if (this.loggedInUser && this.loggedInUser.name !== '') {
      if (window.ga !== undefined) {
        window.ga('set', 'userId', this.loggedInUser.id)
      }
    }
    /* if (this.zap !== undefined) {
      if (window.PUSHER_CHANNEL === undefined) {
        window.PUSHER_CHANNEL = this.$pusher.subscribe('machine_events')
        window.PUSHER_CHANNEL.bind(`machine_${this.zap.id}`, function(data) {
          that.showPusherMessage({
            message: data.text,
            image: data.image
          })
        })
      }
    } */
  },
  beforeCreate() {
    if (window.localStorage.getItem('auth.strategy') == 'chat')
      this.$router.push({ name: 'livechat' })
  },
  beforeMount() {
    if (window.localStorage.getItem('auth.strategy') == 'chat')
      this.$router.push({ name: 'livechat' })
  },
  mounted() {
    this.initcountDown()
  },
  methods: {
    ...mapActions({
      getZap: 'zaps/get'
    }),
    goToPlans() {
      redirectUserToPayment(this.loggedInUser, this.$mixpanel)
    },
    initcountDown() {
      var countDownDate = new Date(this.loggedUser.expiration_date).getTime()
      var self = this
      var x = setInterval(function() {
        var now = new Date().getTime()
        var distance = countDownDate - now
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        hours = hours < 10 ? '0' + hours : hours
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        self.countDown =
          days + ' dias ' + hours + 'h ' + minutes + 'min ' + seconds + 's'
        if (distance < 0) {
          self.countDown = 'EXPIROU'
          clearInterval(x)
        }
      }, 1000)
    },
    toggleMenu() {
      this.menu = this.menu ? false : true
    },
    handleCloseMenu() {
      this.menu = false
    },
    async logout() {
      await this.$auth.logout()
      let route_logout =
        window.localStorage.getItem('auth.strategy') == 'chat'
          ? '/login/chat'
          : '/login'
      window.location = route_logout
      localStorage.clear()
    }
  }
}
</script>

<style scoped>
.page-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  color: #6c757d;
}
.expiration {
  padding-top: 20px;
  color: red;
  text-align: center;
  font-size: 16px;
}
.mentoring-button {
  margin-top: -8px;
  border-radius: 5px 5px 5px 5px;
  height: 40px;
  float: right;
  padding: 10px 30px;
  background: white;
  color: #007aff;
  font-size: 14px;
  border: none;
  font-family: montserrat-regular;
}
</style>
