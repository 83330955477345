import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fc5b2888 = () => interopDefault(import('../pages/atendente/index.vue' /* webpackChunkName: "pages/atendente/index" */))
const _5c3f19a4 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5b808986 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _0f692b27 = () => interopDefault(import('../pages/lite.vue' /* webpackChunkName: "pages/lite" */))
const _621333c1 = () => interopDefault(import('../pages/lite/messaging/new/_phone.vue' /* webpackChunkName: "pages/lite/messaging/new/_phone" */))
const _46295ee7 = () => interopDefault(import('../pages/lite/messaging/_uuid.vue' /* webpackChunkName: "pages/lite/messaging/_uuid" */))
const _11375586 = () => interopDefault(import('../pages/livechat.vue' /* webpackChunkName: "pages/livechat" */))
const _db8ecc70 = () => interopDefault(import('../pages/livechat/_uuid.vue' /* webpackChunkName: "pages/livechat/_uuid" */))
const _15c36d7a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _71c61d5b = () => interopDefault(import('../pages/messaging.vue' /* webpackChunkName: "pages/messaging" */))
const _5f6d36a2 = () => interopDefault(import('../pages/messaging/new/_phone.vue' /* webpackChunkName: "pages/messaging/new/_phone" */))
const _111f5366 = () => interopDefault(import('../pages/messaging/_uuid.vue' /* webpackChunkName: "pages/messaging/_uuid" */))
const _74f5c554 = () => interopDefault(import('../pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _7e3b27e0 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _08a7f119 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _4e2482ec = () => interopDefault(import('../pages/broadcast/new/index.vue' /* webpackChunkName: "pages/broadcast/new/index" */))
const _69ebcae8 = () => interopDefault(import('../pages/login/forgot_password.vue' /* webpackChunkName: "pages/login/forgot_password" */))
const _2c1b4fae = () => interopDefault(import('../pages/login/page.vue' /* webpackChunkName: "pages/login/page" */))
const _49190b2f = () => interopDefault(import('../pages/register/free.vue' /* webpackChunkName: "pages/register/free" */))
const _fe638588 = () => interopDefault(import('../pages/subscription/change/index.vue' /* webpackChunkName: "pages/subscription/change/index" */))
const _31abd18f = () => interopDefault(import('../pages/zap/attention.vue' /* webpackChunkName: "pages/zap/attention" */))
const _3ce6975b = () => interopDefault(import('../pages/zap/benefits.vue' /* webpackChunkName: "pages/zap/benefits" */))
const _5ff07372 = () => interopDefault(import('../pages/zap/first_access.vue' /* webpackChunkName: "pages/zap/first_access" */))
const _37936ed2 = () => interopDefault(import('../pages/zap/ImportContacts.vue' /* webpackChunkName: "pages/zap/ImportContacts" */))
const _6e20c484 = () => interopDefault(import('../pages/zap/qrcode_v2.vue' /* webpackChunkName: "pages/zap/qrcode_v2" */))
const _35beee2a = () => interopDefault(import('../pages/zap/templates.vue' /* webpackChunkName: "pages/zap/templates" */))
const _ceab6264 = () => interopDefault(import('../pages/register/auth/_token.vue' /* webpackChunkName: "pages/register/auth/_token" */))
const _6b349ca0 = () => interopDefault(import('../pages/zap/simulator/_id.vue' /* webpackChunkName: "pages/zap/simulator/_id" */))
const _f535ec1c = () => interopDefault(import('../pages/qrcode/_token.vue' /* webpackChunkName: "pages/qrcode/_token" */))
const _9442806a = () => interopDefault(import('../pages/recovery/_token.vue' /* webpackChunkName: "pages/recovery/_token" */))
const _e9f68806 = () => interopDefault(import('../pages/register/_token.vue' /* webpackChunkName: "pages/register/_token" */))
const _4439a0ae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2764d928 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _47ff1a4a = () => interopDefault(import('../pages/index/broadcast.vue' /* webpackChunkName: "pages/index/broadcast" */))
const _efc6b4f6 = () => interopDefault(import('../pages/index/configuracao.vue' /* webpackChunkName: "pages/index/configuracao" */))
const _61824cb1 = () => interopDefault(import('../pages/index/leads.vue' /* webpackChunkName: "pages/index/leads" */))
const _794c6d1b = () => interopDefault(import('../pages/index/myaccount.vue' /* webpackChunkName: "pages/index/myaccount" */))
const _1e535454 = () => interopDefault(import('../pages/index/sequencia.vue' /* webpackChunkName: "pages/index/sequencia" */))
const _2c32866e = () => interopDefault(import('../pages/index/chat/messaging/_uuid.vue' /* webpackChunkName: "pages/index/chat/messaging/_uuid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/atendente",
    component: _fc5b2888,
    name: "atendente"
  }, {
    path: "/dashboard",
    component: _5c3f19a4,
    name: "dashboard"
  }, {
    path: "/help",
    component: _5b808986,
    name: "help"
  }, {
    path: "/lite",
    component: _0f692b27,
    name: "lite",
    children: [{
      path: "messaging/new/:phone?",
      component: _621333c1,
      name: "lite-messaging-new-phone"
    }, {
      path: "messaging/:uuid?",
      component: _46295ee7,
      name: "lite-messaging-uuid"
    }]
  }, {
    path: "/livechat",
    component: _11375586,
    name: "livechat",
    children: [{
      path: ":uuid?",
      component: _db8ecc70,
      name: "livechat-uuid"
    }]
  }, {
    path: "/login",
    component: _15c36d7a,
    name: "login"
  }, {
    path: "/messaging",
    component: _71c61d5b,
    name: "messaging",
    children: [{
      path: "new/:phone?",
      component: _5f6d36a2,
      name: "messaging-new-phone"
    }, {
      path: ":uuid?",
      component: _111f5366,
      name: "messaging-uuid"
    }]
  }, {
    path: "/onboarding",
    component: _74f5c554,
    name: "onboarding"
  }, {
    path: "/payment",
    component: _7e3b27e0,
    name: "payment"
  }, {
    path: "/subscription",
    component: _08a7f119,
    name: "subscription"
  }, {
    path: "/broadcast/new",
    component: _4e2482ec,
    name: "broadcast-new"
  }, {
    path: "/login/forgot_password",
    component: _69ebcae8,
    name: "login-forgot_password"
  }, {
    path: "/login/page",
    component: _2c1b4fae,
    name: "login-page"
  }, {
    path: "/register/free",
    component: _49190b2f,
    name: "register-free"
  }, {
    path: "/subscription/change",
    component: _fe638588,
    name: "subscription-change"
  }, {
    path: "/zap/attention",
    component: _31abd18f,
    name: "zap-attention"
  }, {
    path: "/zap/benefits",
    component: _3ce6975b,
    name: "zap-benefits"
  }, {
    path: "/zap/first_access",
    component: _5ff07372,
    name: "zap-first_access"
  }, {
    path: "/zap/ImportContacts",
    component: _37936ed2,
    name: "zap-ImportContacts"
  }, {
    path: "/zap/qrcode_v2",
    component: _6e20c484,
    name: "zap-qrcode_v2"
  }, {
    path: "/zap/templates",
    component: _35beee2a,
    name: "zap-templates"
  }, {
    path: "/register/auth/:token?",
    component: _ceab6264,
    name: "register-auth-token"
  }, {
    path: "/zap/simulator/:id?",
    component: _6b349ca0,
    name: "zap-simulator-id"
  }, {
    path: "/qrcode/:token?",
    component: _f535ec1c,
    name: "qrcode-token"
  }, {
    path: "/recovery/:token?",
    component: _9442806a,
    name: "recovery-token"
  }, {
    path: "/register/:token?",
    component: _e9f68806,
    name: "register-token"
  }, {
    path: "/",
    component: _4439a0ae,
    children: [{
      path: "",
      component: _2764d928,
      name: "index"
    }, {
      path: "broadcast",
      component: _47ff1a4a,
      name: "index-broadcast"
    }, {
      path: "configuracao",
      component: _efc6b4f6,
      name: "index-configuracao"
    }, {
      path: "leads",
      component: _61824cb1,
      name: "index-leads"
    }, {
      path: "myaccount",
      component: _794c6d1b,
      name: "index-myaccount"
    }, {
      path: "sequencia",
      component: _1e535454,
      name: "index-sequencia"
    }, {
      path: "chat/messaging/:uuid?",
      component: _2c32866e,
      name: "index-chat-messaging-uuid"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
