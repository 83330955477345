import Chat from './models/chat.js'

Date.prototype.addHours = function (h) {
  const time = h * 60 * 60 * 1000
  this.setTime(this.getTime() + time)
  return this
}

class Session {
  constructor() {
    this.chats = {}
    this.filters = {
      // phone: '5579'
      // attendanceStatus: 'waiting' // started, stopped, waiting
      // unread: true
      // attendant_id: 5,
      // tags: ['Tag 2']
    }
    this.size = 20
    this.attendances = {}
    this.attendancesKeys = {}
    this.leads = {}
    this.debug = false
    this.queriedContacts = {}
    this.total_chats = 0
    this.loadingFilter = false
    this.isNewVersionId = false
  }

  addChat(newChat) {
    let chat = new Chat(newChat)
    if (!this.isNewVersion && chat.newVersionId) {
      this.isNewVersionId = true
    }
    this.chats[chat.id] = chat
    return chat
  }

  addChats(chats) {
    let chat_keys = Object.keys(chats)
    for (let index = 0; index < chat_keys.length; index++) {
      const chat = chats[chat_keys[index]]
      if (chat.id == 'status@broadcast') continue
      this.addChat(chat)
    }
  }

  deleteChat(chatId) {
    delete this.chats[chatId]
  }

  getChats(isFilter = false) {
    if (window.DEBUG) {
      var startTime = new Date()
    }
    if (isFilter) this.loadingFilter = true
    let filteredChats = this._filteredChats()
    const chats = JSON.parse(JSON.stringify(filteredChats))
    this.loadingFilter = false
    if (window.DEBUG) {
      var endTime = new Date()
      var timeDiff = (endTime - startTime) / 1000
      console.log('[session] [debug] getChats time: ' + timeDiff)
    }
    return chats
  }

  clearChats() {
    this.chats = {}
  }

  updateChatsOrder(chat) {
    let temp_chats = Object.assign({}, this.chats)
    this.clearChats()
    const addedChat = this.addChat(chat)
    this.chats = { ...this.chats, ...temp_chats }
    return addedChat
  }

  // Chat
  addChatMessage(message) {
    if (!message) {
      return
    }
    let chat = this.getChat(message?.chatId)
    if (chat) {
      chat.addMessage(message)
      this.updateChatsOrder(chat)
    } else if (message.id) {
      chat = {
        archive: null,
        id: message.chat.id,
        name: message.chat.contact?.pushname || message.chat.id.split('@')[0],
        notSpam: true,
        pendingMsgs: {},
        timestamp: message.timestamp,
        unreadCount: message.chat.unreadCount + (message.fromMe ? 0 : 1),
        msgs: {},
        profilePicThumbObj: null
      }
      let chat = this.updateChatsOrder(chat)
      chat.addMessage(message)
    }
  }

  addOlderChatMessages(chatId, messages) {
    let chat = this.getChat(chatId)
    chat.addOlderMessages(messages)
  }

  getChat(chatId) {
    if (chatId) {
      let chatUsId = chatId.replace('@s.whatsapp.net', '@c.us')
      return this.chats[chatUsId] || this.chats[chatId]
    }
    return null
  }

  chatIdSufix() {
    return this.isNewVersionId ? '@s.whatsapp.net' : '@c.us'
  }

  getMessages(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      return chat.msgs
    }
    return {}
  }

  getMessage(chatId, messageId) {
    let chat = this.getChat(chatId)
    if (chat) {
      return chat.getMessage(messageId)
    }
    return {}
  }

  getTotalChats(version) {
    if (version == 14 || version == 100) return this._filteredChatsTotal()
    return this.total_chats
  }

  clearChat(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.clear()
    }
  }

  setChatMessages(chatId, data) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setMessages(data)
    }
  }

  getChatMessagesCount(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      return chat.getMessagesCount()
    }
  }

  setChatPresence(
    chatId,
    presence,
    presenceParticipant = '',
    lastSeen = undefined
  ) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setPresence(presence, presenceParticipant, lastSeen)
    }
  }

  setAckMessage(message) {
    let chat = this.getChat(message?.chatId)
    if (chat) chat.setAckMessage(message)
  }

  setChatTimestamp(chatId, timestamp) {
    let chat = this.getChat(chatId)
    if (chat) chat.setTimestamp(timestamp)
  }

  setChatLeadTags(chatId, tags) {
    let chat = this.getChat(chatId)
    if (chat && chat.lead) {
      chat.lead.tags = tags
    }
  }

  setChatUnreadCount(chatId, unreadCount) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setUnreadCount(unreadCount)
    }
  }

  setChatName(chatId, name) {
    let chat = this.getChat(chatId)
    if (chat) chat.setName(name)
  }

  setProfilePicThumbObj(chatId, profilePicThumbObj) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setProfilePicThumbObj(profilePicThumbObj)
    }
  }

  setFilters(filters) {
    this.filters = filters
  }

  updateFilters(filters) {
    Object.assign(this.filters, filters)
    this.size = 20
  }

  setChatAttendance(chatId, attendance) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setAttendance(attendance)
    }
  }

  setAttendances(attendances) {
    this.attendances = attendances
    let attendances_keys = Object.keys(attendances)
    for (let index = 0; index < attendances_keys.length; index++) {
      this.setChatAttendance(
        attendances_keys[index],
        attendances[attendances_keys[index]]
      )
    }
  }

  removeAttendancesFromAttendant(attendances) {
    attendances.forEach(attendance => {
      const key = attendance.key
      const chat = this.getChat(key)
      chat.setAttendance({})
    })
  }

  setChatLead(chatId, lead) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setLead(lead)
    }
  }

  setChatLeads(leads) {
    this.leads = leads
    let leads_keys = Object.keys(leads)
    for (let index = 0; index < leads_keys.length; index++) {
      this.setChatLead(leads_keys[index], leads[leads_keys[index]])
    }
  }

  setAttendancesKeys(attendancesKeys) {
    this.attendancesKeys = attendancesKeys
  }

  _filteredChatsPhone(chat) {
    return (
      (!chat.id.includes('-') && chat.id.includes(this.filters.phone)) ||
      (chat.name &&
        chat.name.toUpperCase().includes(this.filters.phone.toUpperCase()))
    )
  }

  _hasAnyFilter() {
    let has = false
    const keys = Object.keys(this.filters)
    for (let index = 0; index < keys.length; index++) {
      const filter = this.filters[keys[index]]
      has = Array.isArray(filter) ? filter.length > 0 : Boolean(filter)
      if (has) break
    }
    return has
  }

  _wichFilter() {
    const keys = Object.keys(this.filters)
    let filterKeys = []
    for (let index = 0; index < keys.length; index++) {
      const filter = this.filters[keys[index]]
      const has = Array.isArray(filter) ? filter.length > 0 : Boolean(filter)
      if (has) filterKeys.push(keys[index])
    }
    return filterKeys
  }

  _filterAttendanceCreateDate(attendance, filters) {
    return (
      new Date(attendance?.created_at * 1000).addHours(-21).getTime() >=
      filters?.attendanceDate
    )
  }

  _isAttendanceStatus(chat, filters) {
    return (
      filters.attendanceStatus &&
      chat.getAttendanceStatus(filters.attendanceStatus)
    )
  }

  _isPhone(chat, filters) {
    const _filteredChatsPhone = (chat, filters) => {
      return (
        (!chat.id.includes('-') && chat.id.includes(filters.phone)) ||
        (chat.name &&
          chat.name.toUpperCase().includes(filters.phone.toUpperCase())) ||
        (chat?.lead?.name?.toUpperCase().includes(filters.phone.toUpperCase()))
      )
    }
    return filters?.phone && _filteredChatsPhone(chat, filters)
  }

  _isAttendanceDate(chat, filters) {
    Date.prototype.addHours = function (h) {
      const time = h * 60 * 60 * 1000
      this.setTime(this.getTime() + time)
      return this
    }
    const _filterAttendanceCreateDate = (attendance, filters) => {
      const dateAttendance = new Date(attendance?.created_at * 1000).addHours(-3)
      const dateFilter = new Date(filters?.attendanceDate).addHours(-3)
      return (
        dateAttendance.getTime() >= dateFilter.getTime()
      )
    }
    return (
      filters?.attendanceDate &&
      chat?.attendance &&
      Object.keys(chat?.attendance).length &&
      _filterAttendanceCreateDate(chat?.attendance, filters)
    )
  }

  _isAttendantId(chat, filters) {
    return (
      filters.attendant_id &&
      chat?.attendance?.attendant_uuid == filters.attendant_id &&
      chat?.attendance?.created_at &&
      !chat?.attendance?.closed_at
    )
  }

  _isChatTag(chat, filters) {
    return (
      filters.tags &&
      filters.tags.length > 0 &&
      Boolean(
        chat?.lead?.tags?.some(tag => {
          const tags = filters?.tags || []
          return tags.includes(tag)
        })
      )
    )
  }

  _filterAndChats(chat) {
    const filtersAnd = {
      attendanceStatus: this._isAttendanceStatus,
      phone: this._isPhone,
      attendanceDate: this._isAttendanceDate,
      attendant_id: this._isAttendantId,
      tags: this._isChatTag
    }
    let status = true
    const filterKeys = this._wichFilter()
    if (filterKeys.length) {
      for (let index = 0; index < filterKeys.length; index++) {
        const filterKey = filterKeys[index]
        const method = filtersAnd[filterKey]
        status = method(chat, this.filters)
        if (!status) break
      }
    }
    return status
  }

  // _filterChats(chat) {
  //   var that = this
  //   if (this._hasAnyFilter())
  //     return (
  //       (this.filters?.phone && this._filteredChatsPhone(chat)) ||
  //       (this.filters.unread && chat.unreadCount != 0) ||
  //       (this.filters.attendant_id &&
  //         chat.attendance.attendant_uuid == this.filters.attendant_id) ||
  //       (this.filters?.attendanceDate &&
  //         chat?.attendance &&
  //         Object.keys(chat?.attendance).length &&
  //         this._filterAttendanceCreateDate(chat?.attendance)) ||
  //       (this.filters.attendanceStatus &&
  //         chat.getAttendanceStatus(this.filters.attendanceStatus)) ||
  //       (this.filters.tags &&
  //         this.filters.tags.length > 0 &&
  //         Boolean(
  //           chat?.lead?.tags?.some(tag => {
  //             const tags = that.filters?.tags || []
  //             return tags.includes(tag)
  //           })
  //         ))
  //     )
  //   return true
  // }

  _filteredChats() {
    let total = 0
    const filteredChats = {}
    let chat_keys = Object.keys(this.chats)
    const length = chat_keys.length
    this.total_chats = length
    for (let index = 0; index < length; index++) {
      const chat_key = chat_keys[index]
      const chat = this.chats[chat_key]
      if (this._filterAndChats(chat)) {
        filteredChats[chat_key] = chat
        total += 1
        // if (total >= this.size && version != 14) break
        if (total >= this.size) break
      }
    }
    return filteredChats // TODO
  }

  _filteredChatsTotal() {
    let total = 0
    let chat_keys = Object.keys(this.chats)
    const length = chat_keys.length
    for (let index = 0; index < length; index++) {
      const chat_key = chat_keys[index]
      const chat = this.chats[chat_key]
      if (this._filterAndChats(chat)) total += 1
    }
    return total
  }

  refreshChats(chatId, chat_data = null) {
    let chat = undefined
    if (chat_data) {
      chat = this.addChat(chat_data)
    } else {
      chat = this.getChat(chatId)
    }
    let chat_updated = Object.assign(
      Object.create(Object.getPrototypeOf(chat)),
      chat
    )
    let attendance = chat_updated.attendance
    let chat_attendance = chat_updated.chat_attendance
    this.deleteChat(chatId)
    let temp_chats = Object.assign(
      Object.create(Object.getPrototypeOf(this.chats)),
      this.chats
    )
    this.chats = {}
    this.addChat(chat_updated)
    this.chats = { ...this.chats, ...temp_chats }
    chat = this.getChat(chatId)
    chat.setAttendance(attendance)
    chat.setChatAttendance(chat_attendance)
  }

  showMoreChats(size) {
    this.size += size
  }

  addQueriedContact(contact) {
    this.queriedContacts = { ...this.queriedContacts, ...contact }
  }

  getQueriedContact(phone) {
    return this.queriedContacts[phone]
  }
}

export default Session
