export const state = () => {
  return {
    zap_id: undefined,
    user: {}
  }
}

export const getters = {
  isAuthenticated(state) {
    return state.auth?.loggedIn
  },
  loggedInUser(state) {
    return state.auth?.user
  },
  loggedUser(state) {
    return state.auth?.user
  },
  loggedUserExpirated(state) {
    if (state.auth.user) {
      let date = new Date(state.auth.user.expiration_date).getTime()
      let now = new Date().getTime()
      return state.auth.user.is_free && date - now < 0
    }
    return false
  },
  loggedUserInactive(state) {
    return state.auth?.user && !state.auth?.user.active
  },
}

export const mutations = {
  set_user(state, user) {
    state.user = user
  },
  set_logged_id_user_type(state, type) {
    state.auth.user.type = type
  }
}

export const actions = {
  async getUserByToken(context, token) {
    return await this.$axios.request({
      url: 'auth/me',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },
  async editUserByToken(context, { form, user_id, token }) {
    return await this.$axios.request({
      method: 'PUT',
      url: `/users/${user_id}`,
      data: form,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  },
  async registerUser({ commit }, form) {
    const resp = await this.$axios.request({
      method: 'POST',
      url: `/users/free`,
      data: form
    })
    commit('set_user', resp.data)
  },
  setLoggedInUserType({ state, commit }, user) {
    if (user.id == state.auth.user.attendant_id)
      commit('set_logged_id_user_type', user.type)
  }
}
