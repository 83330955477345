export const initial_state = ({ current, sequence, steps } = {}) => ({
  current: current ?? '',
  sequence: sequence || {},
  steps: steps || {}
})

export const state = initial_state;

export const getters = {
  isCurrentStep: state => (...steps) => steps.includes(state.current)
}

export const mutations = {
  // Global mutations
  reset(state, { current, sequence, steps } = {}) {
    const initialState = initial_state();
    state.current = current ?? initialState.current
    state.sequence = sequence ?? initialState.sequence
    state.steps = Object.freeze(steps ?? initialState.steps)
},

  setCurrent(state, step) {
    state.current = step
  }
}

export const actions = {
  reset({ commit }, stepper) {
    commit('reset', stepper)
  },

  nextStep({ commit, state }, step) {
    const steps = Object.values(state.steps || {})
    const next = step ?? steps[steps.indexOf(state.current) + 1]

    const { current, sequence } = state
    if (sequence[current]?.includes(next)) {
      commit('setCurrent', next)
    }
  },
  previousStep({ commit, state }, step) {
    const steps = Object.values(state.steps)
    const previous = step ?? steps[steps.indexOf(state.current) - 1]

    commit('setCurrent', previous)
  }
}
