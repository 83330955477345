class Contact {
  constructor(contact) {
    this.id = contact.id.replace('@s.whatsapp.net', '@c.us')
    this.name = contact.name || contact.verifiedName || contact.notify
    this.importName = contact.name
    this.verifiedName = contact.verifiedName
    this.notify = contact.notify
  }
}
    
export default Contact
    