<template>
  <v-app class="white broadcast-layout">
    <Header />

    <main>
      <nuxt />
    </main>

    <whatsapp-support />

    <footer class="page-footer text-center">
      <label>© {{ currentYear }} · <a
        class="copyright-link"
        href="https://clickzap.com.br/"
        target="_blank"
      >
        Clickzap
      </a> · Todos os direitos reservados.</label>
    </footer>
  </v-app>
</template>

<script>
import Header from '@/components/base/header'

import WhatsappSupport from '@/components/whatsapp-support'
import Mixpanel from '@/mixins/mixpanel'

export default {
  components: { Header, WhatsappSupport },
  mixins: [Mixpanel],
  computed: {
    currentYear() {
      return new Date().getFullYear() || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.application {
  font-family: $interFontFamily;
  line-height: inherit;

  ::v-deep {
    .application--wrap {
      display: contents;
    }
  }
}

.broadcast-layout {
  height: 100vh;
  min-height: 100%;
  display: grid;
  grid-template-rows: 0fr 1fr 0fr;
}

.page-footer {
  background-color: $mono100;
  padding: 10px;
  padding-top: 20px;
  font-size: 14px;
  color: #6c757d;
  bottom: 5px;
  margin: 0 auto;
}
.copyright-link {
  text-decoration: none;
}
</style>
