const payment_urls = {
  self_hosted: '/payment/',
  third_parties: {
    default: 'https://clickzap.com.br/planos-e-precos/',
    yearly: 'https://clickzap.com.br/planos-precos-anual/',
    support: 'https://czap.me/UhhMLWZckFkfMjO8b5'
  }
}

export const redirectUserToPayment = (user = {}, $mixpanel) => {
  /* if (user.pending_invoice_url) {
    $mixpanel.track('[payment] Click to Open Pending Invoice Url', {
      invoice_url: user.pending_invoice_url
    })
    return window.open(user.pending_invoice_url, '_blank')
  }*/ 

  if (user.is_free) {
    $mixpanel.track('[payment] Click to Open Checkout', { platform: 'hotmart' })
    // return window.open(payment_urls.third_parties.default, '_blank')
    let redirectTo = user.afilliation_code ? payment_urls.third_parties.default : payment_urls.self_hosted
    return window.open(redirectTo, '_blank')
  }
  $mixpanel.track('[payment] Click to Open Checkout', { platform: 'hotmart' })
  return window.open(payment_urls.third_parties.support, '_blank')
}
