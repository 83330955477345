class Chat {
  constructor(chat) {
    this.newVersionId = chat.id.includes('@s.whatsapp.net')
    this.id = chat.id.replace('@s.whatsapp.net', '@c.us')
    this.msgs = this._filteredMessages(chat.msgs)
    this.preloadTotalMsgs = chat.preloadTotalMsgs || 1
    this.unreadCount = chat.unreadCount
    this.archive = chat.archive
    this.name = chat.name
    this.notSpam = chat.notSpam
    this.pendingMsgs = chat.pendingMsgs
    this.timestamp = chat.timestamp
    this.profilePicThumbObj = chat.profilePicThumbObj

    this.isGroup = chat.id.includes('-')

    this.presence = chat.lastKnownPresence || ''
    this.lastSeen = chat.lastSeen
    this.presenceParticipant = ''

    this.attendance = {}
    this.lead = {}
  }

  getRealId() {
    return this.newVersionId
      ? this.id.replace('@c.us', '@s.whatsapp.net')
      : this.id
  }

  _filteredMessages(messages) {
    const filteredMessages = {}
    for (const key of Object.keys(messages)) {
      const msg = messages[key]
      if (msg.type) {
        filteredMessages[msg.id] = msg
      }
    }
    return filteredMessages
  }

  addMessage(message) {
    this.msgs[message.id] = message
    const count = message?.chat?.unreadCount
    if (count > 0) this.setUnreadCount(count)
  }

  addOlderMessages(messages) {
    this.msgs = this._filteredMessages(messages)
  }

  getLastMessage() {
    var msgs = Object.keys(this.msgs || {})
    if (msgs.length > 0) {
      var msgKey = msgs[msgs.length - 1]
      return this.msgs[msgKey] || {}
    }
    return {}
  }

  getMessagesCount() {
    return Object.keys(this.msgs || {}).length
  }

  hasAttendance() {
    return Object.keys(this.attendance).length > 0
  }

  getAttendanceStatus(status) {
    if (status === 'started' && this.hasAttendance())
      return !this.attendance.closed_at
    else if (status === 'closed' && this.hasAttendance())
      return Boolean(this.attendance.closed_at)
    else if (status === 'waiting') return this.unreadCount > 0
    else return false
  }

  setProfilePicThumbObj(profilePicThumbObj) {
    this.profilePicThumbObj = profilePicThumbObj
  }

  setUnreadCount(value) {
    this.unreadCount = value
  }
  setName(value) {
    this.name = value
  }
  setTimestamp(value) {
    this.timestamp = value
  }
  clear() {
    this.msgs = {}
  }
  update(attr, value) {
    this[attr] = values
  }
  setMessages(data) {
    this.msgs = this._filteredMessages(data)
  }
  setAckMessage(message) {
    let key = message.id.includes('_') ? message.id.split('_')[2] : message.id
    if (key) {
      let msg = this.msgs[key]
      if (msg) {
        msg = JSON.parse(JSON.stringify(msg))
        msg.ack = message.ack
        this.msgs[key] = {}
        this.msgs[key] = msg
      }
    }
  }
  setPresence(presence, presenceParticipant, lastSeen) {
    this.presence = presence
    this.presenceParticipant = presenceParticipant
    if (lastSeen) {
      this.lastSeen = lastSeen
    }
  }
  getMessage(messageId) {
    return this.msgs[messageId]
  }
  setMessageLoaded(msgId, loaded) {
    let msg = this.msgs[msgId]
    if (msg) {
      msg.loaded = loaded
    }
  }
  setAttendance(attendance) {
    this.attendance = attendance
  }
  setLead(lead) {
    this.lead = lead
  }
}

export default Chat
