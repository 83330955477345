export const state = () => ({
  list: [],
  tag: {}
})

export const mutations = {
  set(state, list) {
    state.list = list
  },
  add(state, text) {
    state.list.push({
      text: text,
      done: false
    })
  },
  remove(state, { todo }) {
    state.list.splice(state.list.indexOf(todo), 1)
  },
  toggle(state, todo) {
    todo.done = !todo.done
  },
  set_tag(state, tag) {
    state.tag = tag
  }
}

export const getters = {}

export const actions = {
  async getTags({ commit }, zapId) {
    const response = await this.$axios.request({
      url: `tags/${zapId}`
    })
    commit('set', response.data)
  },
  async createTag({ commit }, [zapId, tag]) {
    const response = await this.$axios.request({
      url: `tags/${zapId}`,
      method: 'post',
      data: tag
    })
    commit('set_tag', response.data)
  }
}
