import Swal from '@/helpers/customSwal.js';

export const initial_state = () => ({
  subscription: {
    data: undefined,
    error: undefined,
    update: {
      error: undefined,
      loading: false
    }
  }
})

export const state = initial_state;

export const getters = {
  loading(state) {
    return !state.subscription.data && !state.subscription.error
  }
}

export const mutations = {
  // Global mutations
  reset(state) {
    state = initial_state
  },

  // Subscription mutations
  getSubscriptionLoading(state) {
    state.subscription.data = undefined
    state.subscription.error = undefined
  },
  getSubscriptionSuccess(state, data) {
    state.subscription.data = data
  },
  getSubscriptionFailure(state, error) {
    state.subscription.error = error
  },

  updateSubscriptionMethodLoading(state, loading) {
    state.subscription.update.error = undefined
    state.subscription.update.loading = loading
  },
  updateSubscriptionMethodSuccess(state, data) {
    state.subscription.data = data
    state.subscription.update.loading = false
  },
  updateSubscriptionMethodFailure(state, error) {
    state.subscription.update.error = error.message
    state.subscription.update.loading = false
  },

}

const baseUrls =  {
  getSubscription: () => `/payment/subscriptions`,
  updateSubscriptionPlan: () => `/payment/subscriptions/plan`,
  updateSubscriptionPaymentMethod: () => `/payment/subscriptions/payment-method`,
  cancelSubscription: () => `/payment/subscriptions`
};

export const actions = {
  reset({ commit }) {
    commit('reset')
  },
  async getSubscription({ commit }) {
    commit('getSubscriptionLoading')
    try {
      const { data } = await this.$axios.get(baseUrls.getSubscription());
      
      commit('getSubscriptionSuccess', data);
      commit('setSelectedPlan', data.plan, { root: true });
    } catch(err) {
      commit('getSubscriptionFailure', err);
    }
  },
  async updateSubscriptionPlan({ commit }, plan_id) {
    try {
      const { data } = await this.$axios.put(baseUrls.updateSubscriptionPlan(), { plan_id });
      
      commit('getSubscriptionSuccess', data)
    } catch(err) {
      commit('getSubscriptionFailure', err);
    }
  },
  async updateSubscriptionMethod(_, payload) {
    const response = await this.$axios.request({
      method: 'put',
      url: baseUrls.updateSubscriptionPaymentMethod(),
      data: payload
    })
    return response?.data || {}
  },
  /**
   * Update a subscription to Bank Slip.
   * @param {Object} data
   * @param {Object} data.client
   */
  async updateSubscriptionToBankSlip({ commit, dispatch }) {
    const payload = { payment_method: "boleto" }

    const data = await dispatch('updateSubscriptionMethod', payload)

    commit('updateSubscriptionMethodSuccess', data)
  },
  /**
   * Update a subscription to Credit Card.
   * @param {Object} data
   * @param {String} data.name
   * @param {String} data.number
   * @param {String} data.verification_value
   * @param {String} data.expiration
   */
  async updateSubscriptionToCreditCard({ commit, dispatch, state }, data) {
    let payment_token
    try {
      payment_token = await dispatch('payment/createPaymentToken', data, { root: true })
    } catch (error) {
      Swal.swalError({
        text: `Erro ao salvar método de pagamento. Alguma das informações digitadas pode estar incorreta. Por favor, corrija e tente novamente!`
      })
      commit('updateSubscriptionMethodFailure', JSON.parse(error.message))
      return
    }

    const payload = {
      payment_method: 'credit_card',
      card_id: payment_token
    }

    await dispatch('updateSubscriptionMethod', payload)

    commit('updateSubscriptionMethodSuccess', data)
  },
  /**
   * Update a subscription
   * @param {Object} data
   */
  async updateSubscription({ commit, dispatch }, { payment_method, data }) {
    commit('updateSubscriptionMethodLoading', true)

    const actions = {
      bank_slip: 'updateSubscriptionToBankSlip',
      credit_card: 'updateSubscriptionToCreditCard'
    }

    const update_action = actions[payment_method]

    try {
      await dispatch(update_action, data)
    } catch(error) {
      Swal.swalError({
        text: `Ocorreu um erro ao atualizar o método de pagamento. Por favor, tente novamente!`
      })
      commit('updateSubscriptionMethodFailure', JSON.parse(error.message))
    } finally {
      commit('updateSubscriptionMethodLoading', false)
    }
  }
}
