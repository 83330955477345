import Chat from './chat.js'
class Session {
  constructor() {
    this.status = {
      batteryLevel: 100,
      connected: true,
      loggedIn: true
    }
    this.me = {
      name: '',
      phone: '',
      profilePicThumbObj: ''
    }
    this.chats = {}
    this.current_chat_id = undefined
    this.chat = undefined
    this.attendances = {}
    this.chat_attendances = {}
    this.chat_leads = {}
    this.open_attendances = {}
    this.closed_attendances = {}
    this.attendant_attendances = {}
  }

  setMe(me) {
    this.me = me
  }

  setStatus(status) {
    this.status = status
  }

  addChat(chat) {
    this.chats[chat.id] = new Chat(chat)
    return this.getChat(chat.id)
  }

  deleteChat(chatId) {
    delete this.chats[chatId]
  }
  setChats(chats, lite) {
    this.chats = {}
    if (lite) {
      let size = Object.keys(chats).length > 20 ? 20 : Object.keys(chats).length
      for (let index = 0; index < size; index++) {
        const chat = chats[Object.keys(chats)[index]]
        this.chats[chat.id] = new Chat(chat)
      }
    } else {
      Object.keys(chats).forEach(key => {
        if (key != 'status@broadcast') {
          const chat = chats[key]
          this.chats[chat.id] = new Chat(chat)
        }
      })
    }
    if (this.attendances) {
      Object.keys(this.attendances).forEach(key => {
        const attendance = this.attendances[key]
        let chat = this.getChat(key)
        if (chat) {
          chat.setAttendance(attendance)
        }
      })
    }
    if (this.chat_attendances) {
      Object.keys(this.chat_attendances).forEach(key => {
        const chat_attendance = this.chat_attendances[key]
        let chat = this.getChat(key)
        if (chat) chat.setChatAttendance(chat_attendance)
      })
    }
  }
  getChatAttendance(chatId) {
    return this.chat_attendances[chatId]
  }
  getChats(size) {
    let chat_keys = Object.keys(this.chats)
    let limit = size + 20 < chat_keys.length ? size + 20 : chat_keys.length
    let chats = {}
    for (let index = 0; index < limit; index++) {
      chats[chat_keys[index]] = this.chats[chat_keys[index]]
    }
    return chats
  }

  clearChats() {
    this.chats = {}
  }

  refreshChats(chatId, chat_data = null) {
    let chat = undefined
    if (chat_data) {
      chat = this.addChat(chat_data)
    } else {
      chat = this.getChat(chatId)
    }
    let chat_updated = Object.assign(
      Object.create(Object.getPrototypeOf(chat)),
      chat
    )
    let attendance = chat_updated.attendance
    let chat_attendance = chat_updated.chat_attendance
    this.deleteChat(chatId)
    let temp_chats = Object.assign(
      Object.create(Object.getPrototypeOf(this.chats)),
      this.chats
    )
    this.chats = {}
    this.addChat(chat_updated)
    this.chats = { ...this.chats, ...temp_chats }
    chat = this.getChat(chatId)
    chat.setAttendance(attendance)
    chat.setChatAttendance(chat_attendance)
  }

  setBattery(battery) {
    this.battery = battery
  }
  // Chat
  addChatMessage(message) {
    let chat = this.getChat(message.chatId)
    if (chat) {
      chat.addMessage(message)
      let unreadCountFromMe = message.fromMe
        ? message.chat.unreadCount
        : message.chat.unreadCount + 1
      let unreadCount =
        message.chatId == this.current_chat_id ? 0 : unreadCountFromMe

      chat.setReadCount(unreadCount)
    } else if (message.id) {
      let msgs = {}
      msgs[message.id] = message
      let chat = {
        archive: null,
        id: message.chat.id,
        name:
          message.chat && message.chat.contact
            ? message.chat.contact.pushname
            : message.chat.id
              ? message.chat.id.split('@')[0]
              : '',
        notSpam: true,
        pendingMsgs: {},
        timestamp: message.timestamp,
        unreadCount: message.chat.unreadCount + 1,
        msgs: msgs,
        profilePicThumbObj: null
      }
      let temp_chats = Object.assign({}, this.chats)
      this.clearChats()
      this.addChat(chat)
      this.chats = { ...this.chats, ...temp_chats }
    }
    this.status.connected = true
  }

  getChat(chatId) {
    return this.chats[chatId]
  }

  getAttendance(attendanceId) {
    return this.attendances[attendanceId]
  }

  getMessages(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      return chat.msgs
    }
    return {}
  }

  getMessage(chatId, messageId) {
    let chat = this.getChat(chatId)
    if (chat) {
      return chat.getMessage(messageId)
    }
    return {}
  }

  clearChat(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.clear()
    }
  }

  refreshChatMessages(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      let chat_updated = Object.assign(
        Object.create(Object.getPrototypeOf(chat)),
        chat
      )
      let temp_msgs = Object.assign({}, chat.msgs)
      this.chats[chat_updated.id].msgs = {}
      let key = Object.keys(chat_updated.msgs)[
        Object.keys(chat_updated.msgs).length - 1
      ]
      this.chats[chat_updated.id].msgs = temp_msgs
      this.chats[chat_updated.id].msgs[key] = chat_updated.msgs[key]
    }
  }

  updateChat(chatId) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.update(attr, value)
    }
  }
  setChatMessages(chatId, data) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setMessages(data)
    }
  }
  setChatMessageLoaded(msg, loaded) {
    let chat = this.getChat(msg.chatId)
    if (chat) {
      chat.setMessageLoaded(msg.id, loaded)
    }
  }
  setChatMessageInput(chatId, message) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setChatMessageInput(message)
    }
  }
  setAckMessage(message) {
    let chat = this.getChat(message.chatId)
    if (chat) {
      chat.setAckMessage(message)
    }
    this.status.connected = true
  }
  setProfilePicThumbObj(chatId, profilePicThumbObj) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setProfilePicThumbObj(profilePicThumbObj)
    }
  }
  setReadCount(chatId, value) {
    let chat = this.getChat(chatId)
    if (chat) {
      chat.setReadCount(value)
    }
  }
  setCurrentChatId(chatId) {
    if (chatId) this.chat = this.getChat(chatId)
    this.current_chat_id = chatId

    return this.chat
  }
  getCurrentChat() {
    return this.chat
  }
  setAttendances(attendances) {
    this.attendances = attendances
  }
  setAttendancesKeys(attendances) {
    this.open_attendances = attendances['open_attendances']
    this.closed_attendances = attendances['closed_attendances']
    this.attendant_attendances = attendances['attendant_attendances']
  }
  setChatAttendances(chats) {
    this.chat_attendances = chats
  }
  addAttendance(attendance) {
    this.attendances[attendance.key] = attendance
    let chat = this.getChat(attendance.key)
    if (chat) {
      chat.setAttendance(attendance)
    }
  }
  addChatAttendance(chat_attendance) {
    this.chat_attendances[chat_attendance.key] = chat_attendance
    let chat = this.getChat(chat_attendance.key)
    if (chat) {
      chat.setChatAttendance(chat_attendance)
    }
  }
  setChatLeads(leads) {
    this.chat_leads = leads
  }
}

export default Session
