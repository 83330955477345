import Message from './message';

class Chat {
  constructor(chat) {
    this.newVersionId = chat.id.includes('@s.whatsapp.net')
    this.id = chat.id.replace('@s.whatsapp.net', '@c.us')
    this.phone = this.id.split('@')[0]
    this.name = chat.name || this.phone || ''
    this.msgs = {}
    this.profilePicThumbObj = undefined
    if (chat.messages) {
      for (const message of chat.messages) {
        this.msgs[message.message.key.id] = new Message(message.message)
      }
    }
    this.unreadCount = chat.unreadCount || 0
    this.notSpam = chat.notSpam || true
    this.timestamp = chat.lastMessageRecvTimestamp
    this.isGroup = chat.id.includes('-') || chat.id.includes("g.us")
    this.chatType = "-"
    if (chat.id.endsWith('s.whatsapp.net') || chat.id.endsWith("c.us")) {
      this.chatType = "chat"
    } else if (chat.id.includes('-') || chat.id.endsWith("g.us")) {
      this.chatType = "group"
    } else {
      this.chatType = chat.id.includes('@') ? chat.id.split('@')[1] : "unknown"
    }

    this.preloadTotalMsgs = 1
    this.archive = false
    this.pendingMsgs = undefined
    this.profilePicThumbObj = undefined
    this.presence = chat.lastKnownPresence || ''
    this.lastSeen = chat.lastSeen
    this.presenceParticipant = ''

    // this.chat = chat

    this.attendance = {}
    this.lead = {}
  }

  getRealId() {
    return this.newVersionId
      ? this.id.replace('@c.us', '@s.whatsapp.net')
      : this.id
  }

  getLastMessage() {
    var msgs = Object.keys(this.msgs || {})
    if (msgs.length > 0) {
      var msgKey = msgs[msgs.length - 1]
      return this.msgs[msgKey] || {}
    }
    return {}
  }

  getMessagesCount() {
    return Object.keys(this.msgs || {}).length
  }

  hasAttendance() {
    return Object.keys(this.attendance).length > 0
  }

  getAttendanceStatus(status) {
    if (status === 'started' && this.hasAttendance())
      return !this.attendance.closed_at
    else if (status === 'closed' && this.hasAttendance())
      return Boolean(this.attendance.closed_at)
    else if (status === 'waiting') return this.unreadCount > 0
    else return false
  }

  setUnreadCount(count) {
    this.unreadCount = count
  }

  setAttendance(attendance) {
    this.attendance = attendance
  }
  setLead(lead) {
    this.lead = lead
  }
}
  
export default Chat
  