import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'loggedInUser',
    ])
  },
  created() {
    if (this.loggedInUser) {
      if (window.DEBUG) {
        console.log('mixpanel identify ' + this.loggedInUser.id)
      }
      this.$mixpanel.identify(this.loggedInUser.id)
    }
  }
}
