export default function ({ $auth, route, redirect }) {
  const safeRoutes = [
    $auth.options.redirect.login,
    "/atendente",
    "/register/free",
    "/register/auth/",
    "/recovery",
    "/qrcode"
  ]
  if (!safeRoutes.find((safe) => route.path?.includes(safe)) && !$auth.loggedIn) {
    redirect($auth.options.redirect.login, { redirect: route.path })
  }
}