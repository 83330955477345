<template>
  <div class="sidebar">
    <div class="sidebar-fixed">
      <Sidenav :routes="routes" :on-logout="onLogout" :active-route="activeRoute" />
    </div>
  </div>
</template>

<script>
import Sidenav from '@/components/base/sidebar/sidenav'

const SIDEBAR_ROUTES = {
  index: {
    label: 'Dashboard',
    icon: '/assets/img/sidebar/dashboard.svg',
    href: 'index'
  },
  'index-broadcast': {
    label: 'Transmissões',
    icon: '/assets/img/sidebar/broadcasts.svg',
    href: 'index-broadcast'
  },
  'index-leads': {
    label: 'Contatos',
    icon: '/assets/img/sidebar/contacts.svg',
    href: 'index-leads'
  },
  'index-sequencia': {
    label: 'Fluxos',
    icon: '/assets/img/sidebar/flows.svg',
    href: 'index-sequencia'
  },
  'index-livechat': {
    label: 'Mensagens',
    icon: '/assets/img/sidebar/messages.svg',
    href: 'livechat'
  },
  settings: {
    label: 'Configurações',
    icon: '/assets/img/sidebar/settings.svg',
    href: 'index-configuracao'
  }
}

export default {
  name: 'Sidebar',
  components: { Sidenav },
  props: {
    onLogout: { type: Function, required: false, default: undefined }
  },
  data: () => ({
    routes: SIDEBAR_ROUTES
  }),
  computed: {
    activeRoute() {
      return this.$route.name
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.sidebar {
  position: relative;
  height: 100%;
  width: 89px;
}

.sidebar-fixed {
  position: fixed;
  top: 64px;
  left: 0;
  bottom: 0;
  padding: 24px;
  background-color: $neutral100;
  border-right: 1px solid $neutral200;
}
</style>
