import qs from 'qs'

export default function({ $axios, app, redirect }) {
  $axios.onRequest(config => {
    config.paramsSerializer = params =>
      qs.stringify(params, { arrayFormat: 'repeat' })
    return config
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    const detail = error.response ? error.response.data.detail : ''
    const message = error?.response?.data?.message
    if ([401].includes(code) && detail == 'Seu plano expirou') {
      app.$auth.logout()
      if (app.router.app.$route.name != 'expired') redirect('/expired')
    } else if (
      [401, 403].includes(code) &&
      detail != 'unauthorized' &&
      message != 'number_phone_used_by_old_machine'
    ) {
      app.$auth.logout()
      if (app.router.app.$route.name != 'login') redirect('/login', { redirect: app.router.app.$route.path })
    }
  })
}
