<template>
  <div class="dashboard-layout" data-app>
    <HeaderNotification />
 
    <Navbar :on-logout="logout" />
    <div class="dashboard-grid">
      <aside class="desktop-only">
        <Sidebar :on-logout="logout" />
      </aside>
      <main class="dashboard-content">
        <nuxt />
      </main>
    </div>

    <whatsapp-support />
  </div>
</template>

<script>
import Navbar from '@/components/base/navbar'
import Sidebar from '@/components/base/sidebar'
import WhatsappSupport from '@/components/whatsapp-support'
import HeaderNotification from '@/components/base/header-notification'

import Mixpanel from '@/mixins/mixpanel'
import Sentry from '@/mixins/sentry'

export default {
  components: { Navbar, Sidebar, WhatsappSupport, HeaderNotification },
  mixins: [Mixpanel, Sentry],
  methods: {
    async logout() {
      try {
        this.$mixpanel.reset()
        await this.$auth.logout()

        const auth_strategy = window.localStorage.getItem('auth.strategy')
        const redirectPath = auth_strategy === 'chat' ? '/login/chat' : '/login'
        window.location = redirectPath

        localStorage.clear()

        if (window.zE) window.zE('webWidget', 'logout')
      } catch (err) {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.dashboard-layout {
  height: 100vh;
  display: grid;
  grid-template-rows: 0fr 1fr 0fr;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;

  @include media-query($small-breakpoint) {
    grid-template-columns: 0fr 1fr;
  }
}

.dashboard-content {
  margin: 0 auto;
  padding: $scale600;
  width: 100%;
  min-width: 100%;
  max-width: $extra-large-breakpoint;

  @include media-query($small-breakpoint) {
    padding: $scale800;
  }
}

.desktop-only {
  display: none !important;
  @include media-query($small-breakpoint) {
    display: inherit !important;
  }
}
</style>
