export const state = () => ({
  list: [],
  link: {}
})

export const mutations = {
  set(state, list) {
    state.list = list
  },
  set_link(state, link) {
    state.link = link
  }
}

export const actions = {
  async load({ commit }, zapId) {
    const resp = await this.$axios.request({
      url: 'grouped_links/',
      params: {
        machine_id: zapId
      }
    })
    commit('set', resp.data)
  },
  async create({ commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: 'grouped_links/',
      data: data
    })
    commit('set_link', resp.data)
  },
  async remove({ commit }, [grouped_link_id]) {
    return await this.$axios.request({
      method: 'delete',
      url: `grouped_links/${grouped_link_id}`
    })
  },
  async update({ commit }, grouped_link) {
    const resp = await this.$axios.request({
      method: 'put',
      url: `grouped_links/${grouped_link.id}`,
      data: grouped_link
    })
    commit('set_link', resp.data)
  }
}
