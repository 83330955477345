import Swal from '@/helpers/customSwal.js';
import axios from 'axios';

export const steps = {
  StripeCheckoutStep: 'stripe_checkout_form',
  SelectMethodStep: 'select_method_form',
  BankSlipStep: 'bank_slip_form',
  BankSlipSuccessStep: 'bank_slip_success',
  CreditCardStep: 'credit_card_form',
  PixStep: 'pix_form',
  SuccessPageStep: 'success'
}

const stepSequence = {
  [steps.StripeCheckoutStep]: [steps.SuccessPageStep],
  [steps.SuccessPageStep]: [] // FINAL STEP
}

const getPreviousStep =  (current_step, should_skip_select_method_step = false) => {
  switch (current_step) {
    case steps.BankSlipStep:
    case steps.CreditCardStep:
    case steps.StripeCheckoutStep:
    case steps.PixStep:
      if (should_skip_select_method_step) return steps.StripeCheckoutStep
      return steps.SelectMethodStep
    case steps.BankSlipSuccessStep:
      return steps.BankSlipStep
    default:
      return steps.StripeCheckoutStep
  }
}

const isNextStepValid = (current, next) => {
  return true
}

export const initial_state = () => ({
  current_step: steps.PersonalDataStep,
  steps: Object.values(steps),
  client_secret: {
    data: undefined,
    loading: false
  },
  offer: {
    data: undefined,
    error: undefined
  },
  plans: {
    selected: undefined,
    category: 'monthly',
    visited: true,
    completed: false,
    data: undefined,
    error: undefined
  },
  personal: {
    name: '',
    cpf_cnpj: '',
  },
  payment: {
    method: undefined,
    bank_slip: {
      name: '',
      email: '',
      cpf_cnpj: ''
    },
    pix: {},
    pulling: {
      data: undefined,
      error: undefined,
      ref: undefined
    },
    visited: false,
    completed: false,
    loading: false,
    data: undefined,
    error: undefined
  }
})

export const state = initial_state

export const getters = {
  isLoadingOffer(state) {
    return !state.offer.data && !state.offer.error
  },
  isLoadingPlans(state) {
    return !state.plans.data && !state.plans.error
  }
}

export const mutations = {
  // Global mutations
  reset(state) {
    state = initial_state()
  },

  // Stepper mutations
  goNextStep(state, step) {
    const next =
      step ?? state.steps[state.steps.indexOf(state.current_step) + 1]
    if (isNextStepValid(state.current_step, next)) {
      state.current_step = next
    }
  },
  goPreviousStep(state, { step: previousStep, skipSelectMethodStep =  false }) {
    // Remove pulling before go to previous step
    const pullingIntervalRef =  state.payment.pulling.ref
    if (state.current_step === steps.PixStep) {
      clearInterval(pullingIntervalRef)
      state.payment.pulling.ref = undefined
    }

    if (!previousStep) {
      state.current_step = getPreviousStep(state.current_step, skipSelectMethodStep)
    } else {
      state.current_step = previousStep
    }
  },

  // Stripe mutations
  getClientSecretLoading(state, data) {
    state.client_secret.data = undefined
    state.client_secret.error = undefined
    state.client_secret.loading = data
  },
  getClientSecretSuccess(state, data) {
    state.client_secret.data = data
    state.client_secret.loading = false
  },
  getClientSecretFailure(state, error) {
    state.client_secret.error = error.message
    state.client_secret.loading = false
  },
  clearClientSecret(state) {
    state.client_secret.data = undefined
    state.client_secret.error = undefined
    state.client_secret.loading = false
  },

  // Offer mutations
  getOfferLoading(state) {
    state.offer.data = undefined
    state.offer.error = undefined
  },
  getOfferSuccess(state, data) {
    state.offer.data = data
  },
  getOfferFailure(state, error) {
    state.offer.error = error
  },

  // Plans mutations
  setSelectedPlan(state, selected) {
    state.plans.selected = selected
    state.plans.completed = true
  },
  setSelectedCategory(state, selected) {
    state.plans.category = selected
  },
  getPlansLoading(state) {
    state.plans.data = undefined
    state.plans.error = undefined
  },
  getPlansSuccess(state, data) {
    state.plans.data = data
  },
  getPlansFailure(state, error) {
    state.plans.error = error
  },

  // Personal mutations
  setPersonalName(state, value) {
    state.personal.name = value
    state.payment.bank_slip.name = value
  },
  setPersonalCPForCNPJ(state, value) {
    state.personal.cpf_cnpj = value
    state.payment.bank_slip.cpf_cnpj = value
  },

  // Payment mutations
  setPaymentMethod(state, value) {
    state.payment.method = value
  },
  createSubscriptionLoading(state, data) {
    state.payment.data = undefined
    state.payment.error = undefined
    state.payment.loading = data
  },
  createSubscriptionSuccess(state, data) {
    state.payment.data = data
    state.payment.loading = false
  },
  createSubscriptionFailure(state, error) {
    state.payment.error = error.message
    state.payment.loading = false
  },

  pullingPixPaymentInfoLoading(state, ref) {
    state.payment.pulling.ref = ref
  },
  pullingPixPaymentInfoSuccess(state, data) {
    state.payment.pulling.data = data
  },
  pullingPixPaymentInfoFailure(state, error) {
    state.payment.pulling.error = error
  },

  // BankSlip mutations
  setBankSlipName(state, value) {
    state.payment.bank_slip.name = value
  },
  setBankSlipEmail(state, value) {
    state.payment.bank_slip.email = value
  },
  setBankSlipCPForCNPJ(state, value) {
    state.payment.bank_slip.cpf_cnpj = value
  }
}
const baseUrls = {
  getOffer: (code) => `/payment/offer/${code}/user-offer`,
  getPlans: () => `/payment/plans`
}

export const actions = {
  reset({ commit }) {
    commit('reset')
  },
  async getOffer({ commit }, { queryOffer, mainOffer }) {
    if (!queryOffer && !mainOffer) return

    commit('getOfferLoading')
    try {
      const { data } = await this.$axios.get(baseUrls.getOffer(queryOffer || mainOffer))
      
      let expiredOffer
      if (!queryOffer && mainOffer) {
        const now = new Date().getTime()
        const expirationDate = new Date(data.expires_at).getTime()
        expiredOffer = expirationDate < now
      }

      commit('getOfferSuccess', expiredOffer ?  undefined : data)
    } catch (err) {
      commit('getOfferFailure', err)
    }
  },
  async getPlans({ commit, state, rootState }, offer_id) {
    commit('getPlansLoading')
    try {
      const { data: plans } = await this.$axios.get(baseUrls.getPlans(), { ...(offer_id && { params: { offer_id } }) })
      
      const { active, expiration_date, subscription_plan_id } = rootState.auth?.user || {}
      const isExpired = new Date(expiration_date) < new Date()

      let subscription = active && !isExpired && subscription_plan_id
      let selectedPlan = plans.find(({ id, period, popular }) => 
          subscription
            ? subscription === id
            : popular && period === state.plans.category
      )

      commit('getPlansSuccess', subscription && selectedPlan ? [selectedPlan] : plans)

      if (!selectedPlan) selectedPlan = plans.find(({ popular }) => popular)

      commit('setSelectedCategory', selectedPlan?.period)
      commit('setSelectedPlan', selectedPlan?.identifier)
    } catch (err) {
      commit('getPlansFailure', err)
    }
  },
  async createSubscription({ commit }, payload) {
    const response = await this.$axios.request({
      method: 'post',
      url: '/payment/subscriptions',
      data: payload
    })
    return response?.data || {}
  },
  async cancelSubscription({ commit }) {
    const response = await this.$axios.request({
      method: 'delete',
      url: '/payment/subscriptions'
    })
    return response?.data || {}
  },
  async createPaymentIntent({ commit }, payload) {
    const response = await this.$axios.request({
      method: 'post',
      url: '/payment/subscriptions',
      data: payload
    })
    return response?.data || {}
  },
  async createPaymentToken({ commit }, data) {
    const card = {
      "number": data?.number.replaceAll(/\s/g,''),
      "holder_name": data?.first_name + ' ' + data?.last_name,
      "exp_month": +data?.month,
      "exp_year": +data?.year,
      "cvv": data?.verification_value,
    }

    const config = {
      url: '/core/v5/tokens',
      method: 'post',
      data: { "type": "card", card },
      params: { appId: process.env.PAGARME_PUBLIC_KEY },
    }

    if (process.env.NODE_ENV !== 'development') config.baseURL = process.env.PAGARME_URL

    const res = await axios.create().request(config)

    return res.data.id
  },
  async pullingPixPaymentInfo({ commit }) {
    const intervalRef = setInterval(async () => {
      try {
        const response = await this.$axios.request({
          method: 'GET',
          url: `/subscriptions/status`
        })

        commit('pullingPixPaymentInfoSuccess', response.data || {})
        if (response.data.status === 'active') {
          clearInterval(intervalRef)
        }
      } catch (error) {
        commit('pullingPixPaymentInfoFailure', error)
      }
    }, 2000) // Interval of 2 secounds
    commit('pullingPixPaymentInfoLoading', intervalRef)
  },

  /**
   * Generate a new payment intent token from Stripe.
   * @param {Object} data
   * @param {Object} data.payment
   * @param {number} data.payment.number
   */
  async createSubscriptionIntent({ commit, dispatch, state }) {
    try {
      const payload = {
        client: {
          name: state.personal.name,
          cpf_cnpj: state.personal.cpf_cnpj
        },
        subscription: {
          method: 'subscription',
          plan_identifier: state.plans.selected.identifier || state.plans.selected,
          offer_id: state.offer?.data?.offer_id
        }
      }

      commit('getClientSecretLoading', true)
      
      const { client_secret } = await dispatch('createPaymentIntent', payload)
      
      commit('getClientSecretSuccess', client_secret)
      
      return { client_secret }
    } catch (error) {
      commit('getClientSecretLoading', false)
      Swal.swalError({
        text: `Ocorreu um erro durante a criação do pagamento. Por favor, tente novamente!`
      })
      return
    }
  },

  /**
   * Create a subscription by Bank Slip.
   * @param {Object} data
   * @param {Object} data.client
   */
  async createSubscriptionByBankSlip({ commit, dispatch, state, rootState }) {
    const payload = {
      client: {
        name: state.personal.name,
        cpf_cnpj: state.personal.cpf_cnpj,
        email: rootState.auth?.user?.email,
      },
      subscription: {
        method: state.payment.method,
        plan_identifier: state.plans.selected,
        offer_id: state.offer?.data?.offer_id
      }
    }

    const data = await dispatch('createSubscription', payload)

    setTimeout(() => {
      commit('createSubscriptionSuccess', data)
      commit('goNextStep', steps.BankSlipSuccessStep)
    }, 0)

  },
  /**
   * Generate a new payment token from Pagar.me and create a subscription.
   * @param {Object} data
   * @param {Object} data.payment
   * @param {number} data.payment.number
   */
  async createSubscriptionByCreditCard({ commit, dispatch, state }, data) {
    let payment_token
    try {
      payment_token = await dispatch('createPaymentToken', data)
    } catch (error) {
      Swal.swalError({
        text: `Erro ao salvar método de pagamento. Alguma das informações digitadas pode estar incorreta. Por favor, corrija e tente novamente!`
      })
      commit('createSubscriptionFailure', JSON.parse(error.message))
      return
    }

    const payload = {
      client: {
        name: state.personal.name,
        cpf_cnpj: state.personal.cpf_cnpj
      },
      subscription: {
        method: state.payment.method,
        plan_identifier: state.plans.selected,
        installments: data.installments,
        offer_id: state.offer?.data?.offer_id
      },
      payment_method: {
        card: {
          token: payment_token
        }
      }
    }

    await dispatch('createSubscription', payload)

    Swal.swalSuccess({
      text: 'Assinatura criada com sucesso!'
    })
    setTimeout(() => {
      commit('goNextStep', steps.SuccessPageStep)
    }, 2000)
  },
  /**
   * Create a subscription by Pix.
   * @param {Object} data
   * @param {Object} data.client
   */
  async createSubscriptionByPix({ commit, dispatch, state }) {
    const payload = {
      client: {
        name: state.personal.name,
        cpf_cnpj: state.personal.cpf_cnpj
      },
      subscription: {
        method: state.payment.method,
        plan_identifier: state.plans.selected
      }
    }

    if (state.payment.data) {
      commit('goNextStep', steps.PixStep)
      return
    }

    const data = await dispatch('createSubscription', payload)

    setTimeout(() => {
      commit('createSubscriptionSuccess', data)
      commit('goNextStep', steps.PixStep)
    }, 0)
  },
  /**
   * Create a new subscription
   * @param {Object} data
   */
  async createNewSubscription({ commit, dispatch, state }, data) {
    commit('createSubscriptionLoading', true)

    const actions = {
      bank_slip: 'createSubscriptionByBankSlip',
      credit_card: 'createSubscriptionByCreditCard',
      pix: 'createSubscriptionByPix'
    }

    const payment_action = actions[state.payment.method]

    try {
      await dispatch(payment_action, data)
    } finally {
      commit('createSubscriptionLoading', false)
    }
  }
}
