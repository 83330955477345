import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'loggedInUser',
    ])
  },
  created() {
    if (this.loggedInUser) {
      if (window.DEBUG) {
        console.log('sentry identify ' + this.loggedInUser.id)
      }
      this.$sentry.setUser({
        id: this.loggedInUser.id,
        username: this.loggedInUser.name,
        email: this.loggedInUser.email,
        subscription: this.loggedInUser.is_free ? "Teste Grátis": "Assinante"
      })
    }
  }
}
