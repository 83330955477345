export const initial_state = () => ({
  offer: {
    data: undefined,
    error: undefined
  },
  plans: {
    selected: undefined,
    category: 'monthly',
    data: undefined,
    error: undefined
  }
})

export const state = initial_state

export const getters = {
  loadingOffer(state) {
    return !state.offer.data && !state.offer.error
  },
  loadingPlans(state) {
    return !state.plans.data && !state.plans.error
  }
}

export const mutations = {
  // Global mutations
  reset(state) {
    state = initial_state()
  },

  // Offer mutations
  getOfferLoading(state) {
    state.offer.data = undefined
    state.offer.error = undefined
  },
  offerSuccess(state, data) {
    state.offer.data = data
  },
  getOfferFailure(state, error) {
    state.offer.error = error
  },

  // Plans mutations
  setSelectedPlan(state, selected) {
    state.plans.selected = selected
    state.plans.completed = true
  },
  setSelectedCategory(state, selected) {
    state.plans.category = selected
  },
  getPlansLoading(state) {
    state.plans.data = undefined
    state.plans.error = undefined
  },
  getPlansSuccess(state, data) {
    state.plans.data = data
  },
  getPlansFailure(state, error) {
    state.plans.error = error
  }
}

const baseUrls = {
  getOffer: (code) => `/payment/offer/${code}/user-offer`,
  getPlans: () => `/payment/plans`
}

export const actions = {
  reset({ commit }) {
    commit('reset')
  },
  async getOffer({ commit }, { queryOffer, mainOffer }) {
    if (!queryOffer && !mainOffer) return

    commit('getOfferLoading')
    try {
      const { data } = await this.$axios.get(baseUrls.getOffer(queryOffer || mainOffer))

      let expiredOffer
      if (!queryOffer && mainOffer) {
        const now = new Date().getTime()
        const expirationDate = new Date(data.expires_at).getTime()
        expiredOffer = expirationDate < now
      }

      commit('offerSuccess', Object.assign(data, { expired: expiredOffer }))
    } catch (err) {
      commit('getOfferFailure', err)
    }
  },
  // TODO: Estudar forma de deixar essa função mais simples, fazendo apenas uma tarefa.
  async getPlans({ commit, state, rootState }, offer_id) {
    commit('getPlansLoading')
    try {
      const { data: plans } = await this.$axios.get(baseUrls.getPlans(), { ...(offer_id && { params: { offer_id } }) })

      const { active, expiration_date, subscription_plan_id } = rootState.auth?.user || {}
      const isExpired = new Date(expiration_date) < new Date()

      let subscription = active && !isExpired && subscription_plan_id
      let selectedPlan = plans.find(({ id, period, popular }) => {
        if (subscription) return subscription === id
        return popular && period === state.plans.category
      })

      commit('getPlansSuccess', subscription && selectedPlan ? [selectedPlan] : plans)

      if (!selectedPlan) selectedPlan = plans.find(({ popular }) => popular)

      commit('setSelectedCategory', selectedPlan?.period)
      commit('setSelectedPlan', selectedPlan?.identifier)
    } catch (err) {
      commit('getPlansFailure', err)
    }
  }
}
