<template>
  <div class="sidenav-item">
    <nuxt-link :to="{ name: href }">
      <div class="sidenav-item-icon" :class="{ '--active': active }">
        <v-tooltip right>
          <div slot="activator" class="sidenav-item-link">
            <img
              v-if="icon"
              :src="icon"
              class="navbar-logo"
              svg-inline
            >
          </div>
          <span>{{ label }}</span>
        </v-tooltip>
      </div>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'SidenavItem',
  props: {
    label: { type: String, required: false, default: '' },
    icon: { type: String, requied: false, default: '' },
    href: { type: String, requied: false, default: '' },
    active: { type: Boolean, requied: false, default: false },
    disclosure: { type: Boolean, requied: false, default: false }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.sidenav-item {
  position: relative;
}

.sidenav-item-icon {
  border-radius: 100px;
  padding: 8px;

  transition: background-color 0.2s linear;

  .sidenav-item-link {
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: $neutral300;
  }

  &.--active {
    background-color: #e3f1ff;
    img {
      filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
    }
  }
}
</style>
