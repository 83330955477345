import { getExtForMimeType } from "../../file"

const notificationTypes = {
  CALL_MISSED_VIDEO: "Chamada de vídeo perdida", 
  CALL_MISSED_VOICE: "Chamada de voz perdida",
  E2E_IDENTITY_CHANGED: false,
  2: "Mensagem não disponível no Whatsapp Web. Visualize essa mensagem no seu celular."
}

const notificationColors = {
  2: 'gray'
}

class Message {
  constructor(message) {
    this.key = message.key
    this.id = message.key.id
    this.chatId = message.key.remoteJid.replace('@s.whatsapp.net', '@c.us')
    this.valid = (
      !message.message?.protocolMessage &&
      !message.message?.reactionMessage &&
      this.chatId !== 'status@broadcast'
    )
    this.fromMe = message.key.fromMe
    this.profilePic = ''
    this.pushName = message.pushName
    this.ack = message.status ? [
      'PENDING',
      'SERVER_ACK',
      'DELIVERY_ACK',
      'READ',
      'PLAYED',
    ].indexOf(message.status) : undefined
    this.status = message.status
    this.quotedMessage = undefined
    this.timestamp = parseInt(message.messageTimestamp || 0)
    this.star = false
    this.caption = undefined
    this.thumbnail = undefined
    this.thumbnailHeight = undefined
    this.thumbnailWidth = undefined
    this.fileName = undefined
    this.notificationColor = undefined

    const participant = message.participant || message.key.participant
    this.participant = participant ? {
      jid: participant,
      name: message.pushName || this.getContactName(participant)
    } : undefined
    this.message = message
    this.defineContent(message.message)
  }

  defineContent(message) {
    this.type = 'chat'
    if (!message) {
      if (this.message.messageStubType) {
        this.type = 'notification'
        this.notificationColor = notificationColors[this.message.messageStubType]
        this.body = notificationTypes[this.message.messageStubType]
        if (!this.body && window.DEBUG) {
          this.body = this.message.messageStubType
        }
        if (!this.body) {
          this.valid = false
        }
      }
      return
    }
    if (message.ephemeralMessage) {
      message = message.ephemeralMessage.message
    }

    if (message.conversation) {
      this.body = message.conversation || '-'
    } else if (message.ephemeralMessage) {
      this.body = message.conversation || '-'
    } else if (message.extendedTextMessage) {
      this.body = message.extendedTextMessage.text || '-'
      this.quotedMessage = this.getQuotedMessage(message.extendedTextMessage)
    } else if (message.imageMessage) { 
      this.type = 'image'
      this.body = message.imageMessage
      this.caption = message.imageMessage.caption
      this.thumbnail = message.imageMessage.jpegThumbnail
      this.isForwarded = message.imageMessage.contextInfo?.isForwarded
      this.quotedMessage = this.getQuotedMessage(message.imageMessage)
    } else if (message.audioMessage) {
      this.type = 'audio'
      this.duration = message.audioMessage.seconds
      this.content = message.audioMessage
      this.quotedMessage = this.getQuotedMessage(message.audioMessage)
    } else if (message.documentMessage || message.documentWithCaptionMessage) {
      const documentMessage = message.documentMessage || message.documentWithCaptionMessage.message.documentMessage
      
      this.type = 'document'
      this.body = documentMessage
      this.content = documentMessage
      this.thumbnail = documentMessage.jpegThumbnail
      this.thumbnailHeight = documentMessage.thumbnailHeight
      this.thumbnailWidth = documentMessage.thumbnailWidth
      this.fileName = documentMessage.fileName
      if (!this.fileName.includes(".")) {
        this.fileName += "." + (getExtForMimeType(documentMessage.mimetype) || "pdf")
      }
      this.quotedMessage = this.getQuotedMessage(documentMessage)
    } else if (message.videoMessage) {
      this.type = 'video'
      this.body = message.videoMessage
      this.caption = message.videoMessage.caption
      this.content = message.videoMessage
      this.thumbnail = message.videoMessage.jpegThumbnail
      this.quotedMessage = this.getQuotedMessage(message.videoMessage)
    } else if (message.stickerMessage) {
      this.type = 'sticker'
      this.body = "[sticker]"
    } else if (message.templateMessage) {
      this.body = message.templateMessage.hydratedTemplate?.hydratedContentText
    }
  }

  getQuotedMessage(messageInfo) {
    if (messageInfo?.contextInfo?.quotedMessage) {
      const jid = messageInfo?.contextInfo?.participant;
      const key = Object.keys(messageInfo?.contextInfo?.quotedMessage)[0];
      const body = messageInfo?.contextInfo?.quotedMessage[key];
      const contactName = this.getContactName(
        messageInfo?.contextInfo?.participant
      );
      const quotedMessage = {
        type: this.getTypeQuoted(body),
        body: body,
        id: messageInfo?.contextInfo?.stanzaId,
        author: contactName,
        chat: {
          id: jid,
          contact: {
            pushname: contactName,
          },
          unreadCount: 0,
        },
        chatId: jid,
        from: jid,
        isGroupMsg: jid?.includes("-"),
        sender: jid,
        to: jid,
        duration: messageInfo?.audioMessage?.seconds,
      };
      return quotedMessage;
    }
  }

  getTypeQuoted(body) {
    if (
      typeof body === "object" &&
      body?.mimetype &&
      body?.mimetype?.includes("image")
    )
      return "image";
    if (
      typeof body === "object" &&
      body?.mimetype &&
      body?.mimetype?.includes("audio")
    )
      return "audio";
    if (
      typeof body === "object" &&
      body?.mimetype &&
      body?.mimetype?.includes("video")
    )
      return "video";
    if (
      typeof body === "object" &&
      body?.mimetype &&
      body?.mimetype?.includes("application")
    )
      return "document";
    return "chat";
  }

  getContactName(participant) {
    return participant.split("@")[0]
  }
}
    
export default Message
      