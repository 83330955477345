import mimetypes from '@/mock/mimetype'

export function bytesToMegaBytes(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2)
}

export function getSelectFileMimetype(fileName) {
  const splited = fileName?.split('.')
  let mimetype = null
  const size = splited?.length
  if (size && size > 1) {
    const extension = splited[size - 1]
    mimetype = mimetypes[extension?.toLowerCase()]
  }
  return mimetype
}

export function getExt(fileName) {
  const splited = fileName?.split('.')
  let ext
  const size = splited?.length
  if (size && size > 1) {
    const extension = splited[size - 1]
    ext = extension?.toLowerCase()
  }
  return ext
}

export function getExtForMimeType(mimetype) {
  for (const key in mimetypes) {
    if (mimetype == mimetypes[key]) {
      return key
    }
  }
}

export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export async function readFile(file) {
  return await new Promise(resolve => {
    let fileReader = new FileReader()
    fileReader.onload = e => resolve(fileReader.result)
    fileReader.readAsDataURL(file)
  })
}

function UUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c == 'x' ? r : r & (0x3 | 0x8)).toString(16)
    }
  )
  return uuid
}

export async function onFilePicked(event) {
  try {
    const files = event.target.files
    if (files && files.length) {
      const file = files[0]
      let size = bytesToMegaBytes(file.size)
      // defini esse valor padrão para todo o sistema
      if (size <= 6) {
        const mimettype = getSelectFileMimetype(file.name)
        if (mimettype && file.type)
          return {
            base64: await readFile(file),
            name: `_uuid_${UUID()}.${getExt(file.name)}`,
            size: bytesToSize(file.size),
            type: file.type
          }
        else 
          return {
            msg: 'O arquivo selecionado não possui uma extensão válida.'
          }
      } else 
        return {
          msg: 'O arquivo selecionado deve ser menor ou igual a 6 MB.'
        }
    } else if (files && !files.length) return null
    return {
      msg: 'Houve um erro ao converter o arquivo selecionado.'
    }
  } catch (error) {
    return {
      msg: 'Houve um erro ao converter o arquivo selecionado.'
    }
  }
}

export function dataURLtoFile(dataurl, fileName) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], fileName, { type: mime })
}

export default {
  onFilePicked,
  dataURLtoFile
}
